import React from 'react';
import moment from 'moment-timezone';
import { Row, Col, Card , Container} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Routes } from '../routes';
import { useTranslation } from 'react-i18next';

const Footer = (props) => {
	const currentYear = moment().get('year');
	const showSettings = props.showSettings;
	const { t } = useTranslation('footer');

	const toggleSettings = (toggle) => {
		props.toggleSettings(toggle);
	};

	return (
		<div>
			<footer className="footer section py-5 ">
				<Container fluid>
					<Row>
						<Col xs={12} lg={6} className="mb-4 mb-lg-0">
							<p className="mb-0 text-center text-xl-left">
								Copyright © {`${currentYear} `}
								<Card.Link
									href="https://appar.io/"
									target="_blank"
									className="text-blue text-decoration-none fw-normal"
								>
									AppAR
								</Card.Link>
							</p>
						</Col>
						<Col xs={12} lg={6}>
							<ul className="list-inline list-group-flush list-group-borderless text-center text-xl-right mb-0">
								<li className="list-inline-item px-0 px-sm-2">
									<Card.Link as={Link} to={Routes.FAQ.path}>
										{t('frequent_questions')}
									</Card.Link>
								</li>
								<li className="list-inline-item px-0 px-sm-2">
									<Card.Link as={Link} to={Routes.Terms.path}>
										{t('terms_and_conditions')}
									</Card.Link>
								</li>
								<li className="list-inline-item px-0 px-sm-2">
									<Card.Link as={Link} to={Routes.PrivacyPolicy.path}>
										{t('notice_of_privacy')}
									</Card.Link>
								</li>
								<li className="list-inline-item px-0 px-sm-2">
									<Card.Link href="https://appar.io/" target="_blank">
										{t('contact_us')}
									</Card.Link>
								</li>
							</ul>
						</Col>
					</Row>
				</Container>
			</footer>
		</div>
	);
};

export default Footer;
