export const Routes = {
	// Admin
	Tags: { path: '/admin/tags' },
	Totems: { path: '/totems' },
	Models: { path: '/models' },
	Institutions: { path: '/institutions' },
	Users: { path: '/users' },
	Notifications: { path: '/notifications' },
	Advertising: { path: '/advertising' },
	CreateAdvertising: { path: '/create-advertising' },
	EditAdvertising: { path: '/edit-advertising' },
	CreateTotems: { path: '/totems/create' },

	// Pages
	PointRoutes: { path: '/routes' },
	CreateRoute: { path: '/routes/create' },
	CreatePoint: { path: '/points/create' },
	CreateTemp: { path: '/points/create/temp' },
	CreateModel: { path: '/models/create' },
	FAQ: { path: '/faq' },
	ForgotPassword: { path: '/forgot' },
	Home: { path: '/home' },
	Map: { path: '/map' },
	Points: { path: '/points' },
	PrivacyPolicy: { path: '/policy' },
	Profile: { path: '/profile' },
	ResetPassword: { path: '/reset' },
	Signin: { path: '/signin' },
	Signup: { path: '/signup' },
	Terms: { path: '/terms' },
	Verify: { path: '/verify' },

	// components
	Forms: { path: '/components/forms' },
	Tables: { path: '/components/tables' },
	WidgetsComponent: { path: '/components/widgets' },
};
