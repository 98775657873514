/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import { HashRouter } from "react-router-dom";
// redux
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

// core styles
import "./scss/volt.scss";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";
import "react-toastify/dist/ReactToastify.css";

import "./i18n";

import HomePage from "./screens/HomePage";
import ScrollToTop from "./components/ScrollToTop";

import { ToastContainer } from "react-toastify";

import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["Authorization"] =
	"Bearer " + localStorage.getItem("token");

const store = createStore(() => {}, applyMiddleware(thunk));

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
	<Provider store={store}>
		<HashRouter>
			<ToastContainer
				position="bottom-center"
				pauseOnHover={false}
				autoClose={3000}
			/>
			<ScrollToTop />
			<HomePage />
		</HashRouter>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
