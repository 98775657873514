import React, { useState, useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTimes,
	faHome,
	faCube,
	faUniversity,
	faAddressCard,
	faRoute,
	faListUl,
	faUsers,
	faQuestionCircle,
	faBell,
	faEnvelope,
	faMapMarkerAlt,
	faMapMarkedAlt,
	faHashtag,
	faUser
} from '@fortawesome/free-solid-svg-icons';
import {
	Nav,
	Image,
	Button,
	Dropdown,
	Accordion,
	Navbar,
	Badge,
} from 'react-bootstrap';
import { Routes } from '../routes';
import Logo from '../assets/img/technologies/Logo_White.png';
import { useTranslation } from 'react-i18next';
import jwtDecode from 'jwt-decode';
import Profile3 from '../assets/img/team/profile-0.png';
import PropTypes from 'prop-types';

const Sidebar = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();
	const { pathname } = location;
	const [show, setShow] = useState(false);
	const showClass = show ? 'show' : '';
	const decoded = localStorage.getItem('token')
		? jwtDecode(localStorage.getItem('token'))
		: null;

	const onCollapse = () => setShow(!show);

	// const CollapsableNavItem = (props) => {
	//   const { eventKey, title, icon, children = null } = props;
	//   const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : '';

	//   return (
	//     <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
	//       <Accordion.Item eventKey={eventKey}>
	//         <Accordion.Button
	//           as={Nav.Link}
	//           className="d-flex justify-content-between align-items-center"
	//         >
	//           <span>
	//             <span className="sidebar-icon">
	//               <FontAwesomeIcon icon={icon} />{' '}
	//             </span>
	//             <span className="sidebar-text">{title}</span>
	//           </span>
	//         </Accordion.Button>
	//         <Accordion.Body className="multi-level">
	//           <Nav className="flex-column">{children}</Nav>
	//         </Accordion.Body>
	//       </Accordion.Item>
	//     </Accordion>
	//   );
	// };

	const NavItem = (props) => {
		const {
			title,
			link,
			external,
			target,
			icon,
			image,
			badgeText,
			badgeBg = 'secondary',
			badgeColor = 'primary',
			onBadgeClick,
		} = props;
		const classNames = badgeText
			? 'd-flex justify-content-start align-items-center justify-content-between'
			: '';
		const navItemClassName = link === pathname ? 'active' : '';
		const linkProps = external ? { href: link } : { as: Link, to: link };

		return (
			<Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
				<Nav.Link {...linkProps} target={target} className={classNames}>
					<span>
						{icon ? (
							<span className="sidebar-icon">
								<FontAwesomeIcon icon={icon} />{' '}
							</span>
						) : null}
						{image ? (
							<Image
								src={image}
								width={20}
								height={20}
								className="sidebar-icon svg-icon"
							/>
						) : null}

						<span className="sidebar-text">{title}</span>
					</span>
					{badgeText ? (
						<Badge
							pill
							bg={badgeBg}
							text={badgeColor}
							onClick={() => onBadgeClick()}
							className="badge-md notification-count ms-2"
						>
							{badgeText}
						</Badge>
					) : null}
				</Nav.Link>
			</Nav.Item>
		);
	};

	NavItem.propTypes = {
		title: PropTypes.string,
		link: PropTypes.string,
		external: PropTypes.bool,
		target: PropTypes.any,
		icon: PropTypes.any,
		image: PropTypes.any,
		badgeText: PropTypes.string,
		badgeBg: PropTypes.string,
		badgeColor: PropTypes.string,
		onBadgeClick: PropTypes.func,
	};

	return (
		<>
			<Navbar
				expand={false}
				collapseOnSelect
				variant="dark"
				className="navbar-theme-primary px-4 d-md-none"
			>
				<Navbar.Brand
					className="me-lg-5"
					as={Link}
					to={Routes.Home.path}
				></Navbar.Brand>
				<Navbar.Toggle
					as={Button}
					aria-controls="main-navbar"
					onClick={onCollapse}
				>
					<span className="navbar-toggler-icon" />
				</Navbar.Toggle>
			</Navbar>
			<CSSTransition timeout={300} in={show} classNames="sidebar-transition">
				<SimpleBar
					className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
				>
					<div className="bg-secondary" style={{ height: '170px' }}>
						<div className="sidebar-inner px-4 pt-1">
							<div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
								<div className="d-flex align-items-center"></div>
								<Nav.Link
									className="collapse-close d-md-none"
									onClick={onCollapse}
								>
									<FontAwesomeIcon icon={faTimes} />
								</Nav.Link>
							</div>
							<div className="" style={{ backgroundColor: 'transparent' }}>
								<div className=" mb-4 mt-md-0">
									<Image
										src={Logo}
										onClick={() => {
											history.push(Routes.Home.path);
										}}
										className="img-fluid mt-2"
										style={{
											cursor: 'pointer',
											height: '48%',
											width: '48%',
											left: 0,
										}}
									/>
									<Dropdown.Divider className="my-3 border-indigo" />
								</div>
								<div
									className="text-center text-md-center mt-md-0"
									onClick={() => {
										history.push(Routes.Profile.path);
									}}
									style={{
										cursor: 'pointer',
										fontWeight: 'bold',
										fontSize: '18px',
									}}
								>
									{decoded && decoded.name}
								</div>

								<div className="mt-3" style={{ zIndex: 10 }}>
									<div
										className="text-center"
										style={{
											backgroundColor: '#fff',
											borderRadius: '50%',
											height: '95px',
											width: '95px',
											cursor: 'pointer',
											margin: 'auto',
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<Image
											onClick={() => {
												history.push(Routes.Profile.path);
											}}
											className=""
											src={
												decoded.profile_image ? decoded.profile_image : Profile3
											}
											style={{
												height: 85,
												width: 85,
												position: 'absotule',
												top: '0',
												left: '0',
												margin: 'auto',
											}}
											roundedCircle
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="sidebar-inner px-3 pt-4 mt-4">
						<p className="sidebar-heading">{t('menu')}</p>

						<Nav className="flex-column pt-3 pt-md-0">
							{/* Basic */}
							<NavItem
								title={t('home')}
								link={Routes.Home.path}
								icon={faHome}
							/>
							<NavItem
								title={t('map')}
								link={Routes.Map.path}
								icon={faMapMarkedAlt}
							/>
							<NavItem
								title={t('points')}
								link={Routes.Points.path}
								icon={faMapMarkerAlt}
							/>
							<NavItem
								title={t('routes')}
								link={Routes.PointRoutes.path}
								icon={faRoute}
							/>
							<Dropdown.Divider className="my-3 text-gray-400" />
							<NavItem
								title={t('profile')}
								link={Routes.Profile.path}
								icon={faUser}
							/>
							<NavItem
								title={t('frequent_questions')}
								link={Routes.FAQ.path}
								icon={faQuestionCircle}
							/>

							{decoded.user_type_id == 1 ? (
								<>
									<Dropdown.Divider className="my-3 text-gray-400" />
									<p className="sidebar-heading">{t('admin')}</p>
									<NavItem
										title={t('advertising')}
										link={Routes.Advertising.path}
										icon={faAddressCard}
									/>
									<NavItem
										title={t('institutions')}
										link={Routes.Institutions.path}
										icon={faUniversity}
									/>
									<NavItem
										title={t('3d_models')}
										link={Routes.Models.path}
										icon={faCube}
									/>
									<NavItem
										title={t('notifications')}
										link={Routes.Notifications.path}
										icon={faBell}
									/>
									<NavItem
										title={t('tags')}
										link={Routes.Tags.path}
										icon={faHashtag}
									/>
									<NavItem
										title={t('totems')}
										link={Routes.Totems.path}
										icon={faMapMarkedAlt}
									/>
									<NavItem
										title={t('users')}
										link={Routes.Users.path}
										icon={faUsers}
									/>
								</>
							) : null}

							{/* {decoded && decoded.user_type_id != 3 &&
                <NavItem title={t('users')} link={Routes.User.path} icon={faUsers} />
              } */}

							<Dropdown.Divider className="my-3 " />
						</Nav>
					</div>
				</SimpleBar>
			</CSSTransition>
		</>
	);
};

export default Sidebar;
