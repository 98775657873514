import React, { useState, useEffect, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Routes } from '../routes';
import { lazyLoadScreen } from '../utils/components';
import Preloader from '../components/Preloader';

// components
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';

// pages
const CreateTemp = lazyLoadScreen('points/createTemp');
const Home = lazyLoadScreen('home/');
const ForgotPassword = lazyLoadScreen('forgotPassword/');
const FAQ = lazyLoadScreen('faq/');
const PrivacyPolicy = lazyLoadScreen('privacyPolicy/');
const ResetPassword = lazyLoadScreen('resetPassword/');
const Signin = lazyLoadScreen('singin');
const Signup = lazyLoadScreen('singup');
const Terms = lazyLoadScreen('public/terms/');
const Verify = lazyLoadScreen('verify/');

const Points = lazyLoadScreen('points');
const PointRoutes = lazyLoadScreen('pointRoutes/');
const Profile = lazyLoadScreen('profile');
const Tags = lazyLoadScreen('admin/tags');
const CreatePoint = lazyLoadScreen('points/createPoint');
const CreateRoute = lazyLoadScreen('pointRoutes/createRoute');
const Totems = lazyLoadScreen('admin/totems');
const CreateTotems = lazyLoadScreen(
	'admin/totems/createTotem'
);
const Models = lazyLoadScreen('admin/models');
const Institutions = lazyLoadScreen('admin/institutions');
const Users = lazyLoadScreen('admin/users');
const Notifications = lazyLoadScreen('admin/notifications');
const Advertising = lazyLoadScreen('admin/advertising');
const CreateAdvertising = lazyLoadScreen(
	'admin/advertising/createAdvertising/'
);
const EditAdvertising = lazyLoadScreen('admin/advertising/editAdvertising/');
const CreateModel = lazyLoadScreen('admin/models/createModel');
const Map = lazyLoadScreen('map/');

const RouteWithLoader = ({ component: Component, ...rest }) => {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => setLoaded(true), 1000);
		return () => clearTimeout(timer);
	}, []);

	return (
		<Route
			{...rest}
			render={(props) => (
				<>
					{' '}
					<Preloader show={loaded ? false : true} /> <Component {...props} />{' '}
				</>
			)}
		/>
	);
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => setLoaded(true), 1000);
		return () => clearTimeout(timer);
	}, []);

	const localStorageIsSettingsVisible = () => {
		return localStorage.getItem('settingsVisible') === 'false' ? false : true;
	};

	const [showSettings, setShowSettings] = useState(
		localStorageIsSettingsVisible
	);

	const toggleSettings = () => {
		setShowSettings(!showSettings);
		localStorage.setItem('settingsVisible', !showSettings);
	};

	return (
		<Route
			{...rest}
			render={(props) => (
				<>
					<Preloader show={loaded ? false : true} />
					<Sidebar />

					<main className="content">
						<Component {...props} />
						<Footer
							toggleSettings={toggleSettings}
							showSettings={showSettings}
						/>
					</main>
				</>
			)}
		/>
	);
};

const HomePage = () => (
	<Suspense fallback={<Preloader show={true} />}>
		<Switch>
			{/* Loader */}
			<RouteWithLoader
				exact
				path={Routes.ForgotPassword.path}
				component={ForgotPassword}
			/>
			<RouteWithLoader
				exact
				path={Routes.PrivacyPolicy.path}
				component={PrivacyPolicy}
			/>
			<RouteWithLoader
				exact
				path={Routes.ResetPassword.path}
				component={ResetPassword}
			/>
			<RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
			<RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
			<RouteWithLoader exact path={Routes.Terms.path} component={Terms} />
			<RouteWithLoader exact path={Routes.Verify.path} component={Verify} />

			{/* Sidebar */}
			<RouteWithSidebar
				exact
				path={Routes.PointRoutes.path}
				component={PointRoutes}
			/>
			<RouteWithSidebar
				exact
				path={Routes.CreateTemp.path}
				component={CreateTemp}
			/>
			<RouteWithSidebar
				exact
				path={Routes.FAQ.path}
				component={FAQ}
			/>
			<RouteWithSidebar exact path={Routes.Home.path} component={Home} />
			<RouteWithSidebar exact path={Routes.Points.path} component={Points} />
			<RouteWithSidebar exact path={Routes.Models.path} component={Models} />
			<RouteWithSidebar
				exact
				path={Routes.Totems.path}
				component={Totems}
			/>
			<RouteWithSidebar
				exact
				path={Routes.Institutions.path}
				component={Institutions}
			/>
			<RouteWithSidebar exact path={Routes.Users.path} component={Users} />
			<RouteWithSidebar
				exact
				path={Routes.Notifications.path}
				component={Notifications}
			/>
			<RouteWithSidebar
				exact
				path={Routes.Advertising.path}
				component={Advertising}
			/>
			<RouteWithSidebar
				exact
				path={Routes.CreateAdvertising.path}
				component={CreateAdvertising}
			/>
			<RouteWithSidebar
				exact
				path={Routes.EditAdvertising.path}
				component={EditAdvertising}
			/>
			<RouteWithSidebar
				exact
				path={Routes.Profile.path + '/:id?'}
				component={Profile}
			/>
			<RouteWithSidebar
				exact
				path={`${Routes.CreatePoint.path}/:type`}
				component={CreatePoint}
			/>
			<RouteWithSidebar
				exact
				path={Routes.CreateRoute.path + '/:id?'}
				component={CreateRoute}
			/>
			<RouteWithSidebar
				exact
				path={Routes.CreateModel.path}
				component={CreateModel}
			/>
			<RouteWithSidebar
				exact
				path={Routes.CreateTotems.path}
				component={CreateTotems}
			/>
			<RouteWithSidebar exact path={Routes.Map.path} component={Map} />

			{/* Admin */}
			<RouteWithSidebar exact path={Routes.Tags.path} component={Tags} />

			<Redirect to={Routes.Signin.path} />
		</Switch>
	</Suspense>
);

export default HomePage;
