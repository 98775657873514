import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them)

i18n
	.use(HttpApi)
	.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		supportedLngs: ['es', 'en'],
		fallbackLng: 'es',
		debug: false,
		defaultNS: 'common',
		detection: {
			order: ['cookie', 'htmlTag'],
			caches: ['cookie'],
		},
		react: { useSuspense: true },
		backend: {
			loadPath: '/locales/{{lng}}/{{ns}}.json',
		},
	});

export default i18n;
