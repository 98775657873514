import React from 'react';
import { Image, Spinner } from 'react-bootstrap';
import Logo from '../assets/img/technologies/Logo_Blue.png';

const Preloader = (props) => {
	const { show } = props;

	return (
		<div>
			<div
				className={`preloader bg-soft flex-column justify-content-center align-items-center ${
					show ? '' : 'show'
				}`}
			>
				<Spinner animation="border" variant="secondary" />
				<Image className="mt-3 mb-6" src={Logo} height={50} />
			</div>
		</div>
	);
};

export default Preloader;
